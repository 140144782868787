<template>
  <div>
    <v-row class="mt-3 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="/verein" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-card-account-details-star</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          AKTIVITÄTEN VERWALTEN
        </h3>
      </v-col>
      <v-col cols="auto">
        <v-btn
          :fab="$vuetify.breakpoint.smAndDown"
          :text="$vuetify.breakpoint.mdAndUp"
          @click="newaktivitaet = true"
        >
          <v-icon class="mr-md-2">mdi-plus</v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">Neue Aktivität</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-row justify="center" v-if="user.data.aktivitaeten">
        <v-col
          cols="11"
          class="pa-0 ma-0"
          v-for="(aktivitaet, index) in aktivitaeten"
          :key="index"
        >
          <cardbutton
            :title="aktivitaet.name"
            icon="mdi-card-account-details-star"
            :textcolor="template.colors.block_text"
            :bgcolor="template.colors.blocks"
            upper
            :chev="
              !template.var.standard_aktivitaeten.includes(aktivitaet.name)
            "
            :to="
              template.var.standard_aktivitaeten.includes(aktivitaet.name)
                ? undefined
                : `/verein/aktivitaeten/${aktivitaet.index}`
            "
          />
        </v-col>
      </v-row>
      <v-col
        cols="12"
        v-if="!user.data.aktivitaeten || user.data.aktivitaeten.length == 0"
      >
        <v-card dark class="rounded-xl pa-3">
          <v-row style="min-height: 30vh;" justify="center" v-if="wizard == 0">
            <v-col cols="11" align-self="center" class="text-center">
              <h3 class="font-weight-light">
                Es wurden derzeit noch keine Aktivitäten in deinem Verein
                angelegt.
              </h3>
            </v-col>
            <v-col cols="11" class="text-center">
              <v-btn color="primary" large @click="newabteilung = true">
                Aktivität erstellen
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <CreateAktivitaet
      v-if="newaktivitaet"
      v-on:closing="
        newaktivitaet = false
        sortieren()
      "
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import router from '../../../routes/index'
import store from '../../../store'
import cardbutton from '../../../elements/cards/card-button'
import CreateAktivitaet from './createAktivitaet'

export default {
  name: 'Anwesenheitsliste',
  data() {
    return {
      benutzer: [],
      aktivitaeten: [],
      loading: false,
      datum: '',
      adatum: false,
      wizard: 0,
      newaktivitaet: false,
      sortierung: 'Name (aufsteigend)',
    }
  },
  components: {
    CreateAktivitaet,
    cardbutton,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    this.sortieren()
  },
  methods: {
    addNull(val) {
      if (val > 10) {
        return val
      }
      return '0' + val
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    sortieren() {
      this.aktivitaeten = []
      var i = 0
      this.user.data.aktivitaeten.forEach((userAkt) => {
        this.aktivitaeten.push({
          name: userAkt,
          index: i,
        })
        i++
      })
      function compareNameASC(a, b) {
        if (a.name > b.name) {
          return 1
        } else {
          return -1
        }
      }
      function compareNameDESC(a, b) {
        if (a.name > b.name) {
          return -1
        } else {
          return 1
        }
      }

      if (this.sortierung == 'Name (aufsteigend)') {
        this.aktivitaeten.sort(compareNameASC)
      }
      if (this.sortierung == 'Name (absteigend)') {
        this.aktivitaeten.sort(compareNameDESC)
      }
    },
  },
}
</script>
